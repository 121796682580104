<template>
  <div class="addUser">
    <div class="item name">
      <div class="item-l">姓名</div>
      <div class="item-r">
        <v-input
          v-model="form.applicantsName"
          inputAlign="right"
          :maxlength="10"
          placeholder="请填写姓名"
        />
      </div>
    </div>
    <div class="item sex">
      <div class="item-l">性别</div>
      <div class="item-r" style="margin-left: 16px">
        <v-radio-group
          :radioObj="sexOps"
          :value.sync="form.applicantsSex"
        ></v-radio-group>
      </div>
    </div>
    <div class="item family">
      <div class="item-l">是否是我的家人</div>
      <div class="item-r" style="margin-left: 16px">
        <v-radio-group
          :disabled="!!isEdit || activityCategory === 20"
          :radioObj="familyOps"
          :value.sync="form.isFamily"
        ></v-radio-group>
      </div>
    </div>
    <template v-if="form.isFamily === 1">
      <div class="item familyType">
        <div class="item-l">家人类型</div>
        <div class="item-r" style="margin-left: 16px">
          <v-radio-group
            :radioObj="familyTypeOps"
            :value.sync="form.applicantsRelation"
          ></v-radio-group>
        </div>
      </div>
      <div class="item memberCode">
        <div class="item-l">身份证号码</div>
        <div class="item-r">
          <v-input
            type="number"
            inputAlign="right"
            :maxlength="18"
            v-model="form.memberCode"
            placeholder="请填写身份证号码"
          />
        </div>
      </div>
    </template>
    <template v-if="form.isFamily === 2">
      <div class="item tel">
        <div class="item-l">手机号</div>
        <div class="item-r">
          <v-input
            type="number"
            inputAlign="right"
            :maxlength="11"
            v-model="form.applicantsPhone"
            placeholder="请填写手机号"
          />
        </div>
      </div>
    </template>
    <div class="operaBtn" @click="save">
      <div class="submit">保存</div>
    </div>
    <!-- <v-picker
      :columns="relationList"
      :valueShow="pickShow"
      :valueKey="valueKey"
      @clickOverlay="clickPickOverlay"
      @cancel="cancelPick"
      @confirmPicker="confirmPick"
    ></v-picker> -->
    <!-- <v-dateTimePicker
      :valueShow="show"
      v-model="valueDate"
      @clickOverlay="clickOverlay"
      @cancel="cancel"
      @confirmPicker="confirmPicker"
    ></v-dateTimePicker> -->
  </div>
</template>

<script>
import { addPeopleUrl, editPeopleUrl, addPeopleDetailUrl } from "./api.js";
export default {
  name: "addUser",
  data() {
    return {
      activityCategory: undefined, // 活动类型 20-时间银行 19-幸福学堂 1-热门活动
      scheduleId: "",
      activityId: "",
      applicantsId: "",
      isEdit: "",
      valueKey: "value",
      form: {
        // src: require("./img/numberOfPeople.png"),
        applicantsName: "",
        applicantsPhone: "",
        applicantsSex: 1,
        // applicantsBirthday: "",
        applicantsRelation: undefined, // 家人类型
        // userId: "",
        // relation: "",

        isFamily: 1, // 是否家人
        // familyType: undefined,
        memberCode: "", // 身份证号码
      },
      // relationList,
      pickShow: false,
      valueDate: new Date(),
      show: false,
      valueObj: "1",
      timer: null,
      familyTypeOps: [
        { name: 1, value: "孩子" },
        { name: 2, value: "父母" },
      ],
      familyOps: [
        { name: 1, value: "是" },
        { name: 2, value: "否" },
      ],
      sexOps: [
        { name: 1, value: "男" },
        { name: 2, value: "女" },
      ],
    };
  },
  created() {
    this.activityId = this.$route.query.activityId;
    this.applicantsId = this.$route.query.applicantsId;
    this.isEdit = this.$route.query.isEdit;
    this.scheduleId = this.$route.query.scheduleId;
    this.activityCategory = Number(this.$route.query.activityCategory);
    console.log(this.activityCategory, "this.activityCategory---");
    if (this.isEdit) {
      this.getdetail();
    }
  },
  computed: {
    userId() {
      return this.$store.state.userId;
    },
  },
  destroyed() {
    clearTimeout(this.timer);
  },
  methods: {
    save() {
      if (!this.vaild()) {
        return;
      }
      this.saveRequest();
    },
    vaild() {
      console.log(this.form);
      if (!this.form.applicantsName) {
        this.$toast({ message: "请填写姓名" });
        return;
      }
      if (!this.form.applicantsSex) {
        this.$toast({ message: "请选择性别" });
        return;
      }
      if (!this.form.isFamily) {
        this.$toast({ message: "请选择是否我的家人" });
        return;
      }
      if (this.form.isFamily === 1 && !this.form.applicantsRelation) {
        this.$toast({ message: "请选择家人类型" });
        return;
      }
      if (this.form.isFamily === 1 && !this.form.memberCode) {
        this.$toast({ message: "请输入身份证号码" });
        return;
      }
      if (this.form.isFamily === 2 && !this.form.applicantsPhone) {
        this.$toast({ message: "请填写手机号" });
        return;
      }
      const phoneRegExp =
        /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/;
      if (
        this.form.isFamily === 2 &&
        !phoneRegExp.test(this.form.applicantsPhone)
      ) {
        this.$toast("手机号格式错误");
        return;
      }
      const cardCodeRegExp = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
      if (
        this.form.isFamily === 1 &&
        !cardCodeRegExp.test(this.form.memberCode)
      ) {
        this.$toast("身份证号格式错误");
        return;
      }
      return true;
    },
    // confirmPick(oValue) {
    //   this.pickShow = false;
    //   this.form.applicantsRelation = oValue.key;
    //   this.form.relation = oValue.value;
    // },
    // cancelPick() {
    //   this.pickShow = false;
    // },
    // clickPickOverlay() {
    //   this.pickShow = false;
    // },
    // confirmPicker(value) {
    //   this.show = false;
    //   this.form.applicantsBirthday = moment(value).format("YYYY-MM-DD");
    // },
    // cancel() {
    //   this.show = false;
    // },
    // clickOverlay() {
    //   this.show = false;
    // },
    // showRelation() {
    //   this.pickShow = true;
    // },
    // showDate() {
    //   this.show = true;
    // },
    saveRequest() {
      let params = {
        editingType: this.form.isFamily, // 编辑类型：1家人，2参与人
        activityId: this.activityId,
        scheduleId: this.scheduleId,
        userId: this.userId,
        // applicantsBirthday: this.form.applicantsBirthday,
        // applicantsName: this.form.applicantsName,
        // applicantsPhone: this.form.applicantsPhone,
        // applicantsRelation: this.form.applicantsRelation,
        // applicantsSex: this.form.applicantsSex,
        ...this.form,
        applicantsRelation: undefined, // 字段为undefined时，该字段不会传给后端
        familyType: this.form.applicantsRelation, // 家人类型
      };
      if (this.isEdit) {
        params.applicantsId = this.applicantsId;
      }
      this.$axios
        .post(`${this.isEdit ? editPeopleUrl : addPeopleUrl}`, params)
        .then((res) => {
          if (res.code === 200) {
            this.$toast({ message: "操作成功", duration: 300 });
            // this.$router.replace({
            //   name: "sureSubmit",
            //   query: {
            //     activityId: this.activityId,
            //     scheduleId: this.scheduleId,
            //   },
            // });
            this.$router.go(-1);
            // this.$router.replace({
            //   name: "sureSubmit",
            //   query: {
            //     activityId: this.activityId,
            //     scheduleId: this.scheduleId,
            //   },
            // });
          } else {
          }
        });
    },
    getdetail() {
      let params = {
        applicantsId: this.applicantsId,
        userId: this.userId,
      };

      this.$axios.get(`${addPeopleDetailUrl}`, { params }).then((res) => {
        if (res.code == 200) {
          // this.form.applicantsName = res.data.applicantsName;
          // this.form.applicantsPhone = res.data.applicantsPhone;
          // this.form.applicantsSex = res.data.applicantsSex;
          // this.form.applicantsBirthday = res.data.applicantsBirthday;
          // this.form.applicantsRelation = res.data.applicantsRelation;
          Object.keys(this.form).forEach((key) => {
            this.form[key] = res.data[key];
          });
          // this.form.relation = getOption(
          //   res.data.applicantsRelation,
          //   relationList,
          //   "key"
          // ).value;
          console.log(this.form, 322);
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.addUser {
  // overflow: hidden;
  min-height: 100vh;
  box-sizing: border-box;
  color: #1b1c33;
  font-size: 32px;
  padding: 32px;
  padding-bottom: calc(132px + constant(safe-area-inset-bottom));
  padding-bottom: calc(132px + env(safe-area-inset-bottom));
  .arrow::after {
    position: absolute;
    content: "";
    width: 32px;
    height: 32px;
    background: url(./img/tip.png) no-repeat;
    background-size: 100% 100%;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
  .headImg {
    padding: 25px 0;
    position: relative;
    display: flex;
    padding-right: 32px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    .item-l {
      font-size: 32px;
    }
    .head {
      width: 80px;
      height: 80px;
      vertical-align: middle;
      border-radius: 50%;
    }
  }
  .item {
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    .item-l {
      font-size: 32px;
      font-weight: 400;
      color: #323334;
    }
    .item-r {
      flex: 1;
      text-align: right;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      input {
        height: 100%;
      }
    }
  }
  .sex,
  .family,
  .familyType {
    padding: 26px 0;
  }
  .operaBtn {
    width: 100%;
    position: fixed;
    z-index: 1;
    bottom: 40px;
    left: 0;
    display: flex;
    justify-content: center;
    padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
    padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
    .submit {
      width: 550px;
      height: 66px;
      font-size: 28px;
      line-height: 66px;
      text-align: center;
      font-weight: bold;
      color: #ffffff;
      background: linear-gradient(180deg, #72a8ff 0%, #3680ff 100%);
      border-radius: 10px;
    }
    // width: 100%;
    // position: fixed;
    // bottom: 0;
    // left: 0;
    // right: 0;
    // margin: auto;
    // height: 96px;
    // background: #007eff;
    // font-size: 32px;
    // line-height: 96px;
    // text-align: center;
    // color: #fff;
  }
}
</style>
<style lang="less">
.addUser {
  .van-uploader__upload,
  .van-uploader__preview-image,
  .van-uploader__file {
    width: 80px;
    height: 80px;
  }

  .van-radio-group {
    justify-content: flex-end;
  }
}
</style>
